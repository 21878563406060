<template>
  <div class="mobile-app">
    <el-container>
      <el-header class="fixed-header">
        <el-button icon="el-icon-arrow-left" @click="goToHome" type="text" class="back-button"></el-button>
        <h1>故障详情</h1>
      </el-header>
      <el-main class="scrollable-main">
        <el-form label-width="100px" size="large" style="width: 90%">
          <el-form-item label="报修人">
            <span>{{users.find(v => v.id === user.id) ? users.find(v => v.id === user.id).nickname : ''}}</span>
          </el-form-item>
          <el-form-item label="设备">
            <span>{{equipments.find(v => v.id === reports.equipmentId) ? equipments.find(v => v.id === reports.equipmentId).name : ''}}</span>
          </el-form-item>
          <el-form-item label="工单类型">
            <span>{{ reports.type }}</span>
          </el-form-item>
          <el-form-item label="故障类型">
            <span>{{ reports.failureType }}</span>
          </el-form-item>
          <el-form-item label="报修时间">
            <span>{{ reports.recordTime }}</span>
          </el-form-item>
          <el-form-item label="故障时间">
            <span>{{ reports.failureTime }}</span>
          </el-form-item>
          <el-form-item label="故障描述">
            <span>{{ reports.description }}</span>
          </el-form-item>
          <el-form-item label="故障图">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <el-image
                  v-for="(file, index) in reportFiles"
                  :key="index"
                  :src="file.fileUrl"
                  :preview-src-list="reportFiles.map(item => item.fileUrl)"
                  style="width: 100px; height: 100px; margin-bottom: 10px;"
              >
              </el-image>
            </div>
          </el-form-item>
          <el-form-item label="工时">
            <span v-if="records.workHour">{{ records.workHour }}</span>
            <span v-else>无</span>
          </el-form-item>
          <el-form-item label="维修说明">
            <span v-if="records.shuoming">{{ records.shuoming }}</span>
            <span v-else>无</span>
          </el-form-item>
          <el-form-item label="故障分析">
            <span v-if="records.analysis">{{ records.analysis }}</span>
            <span v-else>无</span>
          </el-form-item>
          <el-form-item label="到达图" v-if="records.avatarUrl">
            <el-image
                style="width: 100px; height: 100px"
                :src="records.avatarUrl"
                :preview-src-list="[records.avatarUrl]">
            </el-image>
          </el-form-item>
          <el-form-item label="完成图">
            <div style="display: flex; flex-wrap: wrap; gap: 10px;">
              <el-image
                  v-for="(file, index) in recordFiles"
                  :key="index"
                  :src="file.fileUrl"
                  :preview-src-list="recordFiles.map(item => item.fileUrl)"
                  style="width: 100px; height: 100px; margin-bottom: 10px;"
              >
              </el-image>
            </div>
          </el-form-item>
        </el-form>
      </el-main>
      <!-- 底部导航栏 -->
      <el-footer class="fixed-footer">
        <el-row type="flex" justify="center">
          <el-col :span="8" class="footer-item">
            <router-link to="/mobile">首页</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mequipment">设备知识库</router-link>
          </el-col>
          <el-col :span="8" class="footer-item">
            <router-link to="/mperson">个人信息</router-link>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: "MHistory",
  data() {
    return {
      form: {},
      reports: [],
      users: [],
      equipments: [],
      records: [],
      recordFiles: [],
      reportFiles: [],
      reportId: null,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      console.log('item id:', this.$route.params.id);
      this.reportId = this.$route.params.id;
      this.request.get("/repairReport/"+this.reportId).then(res => {
        this.reports = res.data;
      });
      this.request.get("/user",this.reportId).then(res => {
        this.users = res.data;
      });
      this.request.get("/equipment",this.reportId).then(res => {
        this.equipments = res.data;
      });
      this.request.get("/failureRecord/findByReportId/"+this.reportId).then(res => {
        this.records = res.data;
        this.request.get("/comment/findRecord/"+this.records.id).then(res => {
          this.comments = res.data;
        });
        this.request.get("/recordFile/getByRecordId/"+this.records.id).then(res => {
          this.recordFiles = res.data
        })
        this.request.get("/reportFile/getByReportId/"+this.records.reportId).then(res => {
          this.reportFiles = res.data
        })
      });
    },
    // 返回首页
    goToHome() {
      this.$router.push('/mequipment');
    },
    startRepair(){
      const formData = {
        reportId: this.reportId,
        userId: this.user.id, // 接单人的 id
        equipmentId: this.reports.equipmentId,
        type: this.reports.type,
        avatarUrl: this.reports.avatarUrl
      };
      this.request.post("/failureRecord", formData).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
      this.request.post("/repairReport/start/"+this.reportId)
    }
  },
};
</script>

<style scoped>
.mobile-app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.fixed-header, .fixed-footer {
  background-color: rgb(38, 52, 69);
  color: #fff;
  text-align: center;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1000; /* 确保在顶部 */
}

.fixed-header {
  top: 0;
}

.fixed-footer {
  bottom: 0;
}

.scrollable-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-top: 60px; /* 保证 el-main 在 el-header 下面 */
  margin-bottom: 60px; /* 保证 el-main 在 el-footer 上面 */
}

.footer-item {
  line-height: 40px;
  text-align: center;
}

.footer-item a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.footer-item a:hover {
  text-decoration: underline;
}
</style>
